import * as React from "react"
import { useContext } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout, { layoutContext } from "../components/layout"
import Seo from "../components/seo"



const BlankPage = () => {


  return (
    <Layout>
      <layoutContext.Consumer>
        {
          ({ setPopupStatus }) => (
            <>
              <Seo title="Blank" />






            </>
          )
        }
      </layoutContext.Consumer>





    </Layout>
  )
}


export default BlankPage
